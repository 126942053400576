import React from 'react';
import { Link } from 'gatsby';
import SEO from '../../components/Seo';
import ContentContainer from '../common/ContentContainer';
import ContentfulContent from '../common/ContentfulContent';
import Layout from '../Layout';
import { formatDate } from './../../util';
import './../layout.css';
import './LayoutCommon.css';

type NewsItem = {
  title: string;
  publishedDate: string;
  slug: string;
};

interface OtherNewsItemProps {
  newsItem: NewsItem;
}

const OtherNewsItem: React.FC<OtherNewsItemProps> = props => {
  return (
    <Link to={`/news/${props.newsItem.slug}`}>
      <div className="bg-white rounded-lg shadow mt-4 p-4 cursor-pointer">
        <h3 className="text-gray-700 font-semibold">{props.newsItem.title}</h3>
        <p className="text-xs text-gray-500 uppercase mt-1">
          {formatDate(new Date(props.newsItem.publishedDate), true)}
        </p>
      </div>
    </Link>
  );
};

interface OtherNewsItemsProps {
  newsItems: NewsItem[];
}
const OtherNewsItems: React.FC<OtherNewsItemsProps> = props => {
  return (
    <>
      {props.newsItems.map((newsItem: NewsItem, i: number) => {
        return <OtherNewsItem key={i} newsItem={newsItem} />;
      })}
    </>
  );
};

interface NewsItemContentProps {
  pageData: any;
}

const NewsItemContent = (props: NewsItemContentProps) => {
  return (
    <>
      {props.pageData?.image && (
        <div
          className="rounded-t-lg h-96 w-full bg-cover"
          style={{
            backgroundImage: props.pageData?.image
              ? `url(${props.pageData?.image?.fluid?.src})`
              : ''
          }}
        ></div>
      )}
      <section className="bg-white rounded-lg shadow-md p-12">
        <h1 className="font-bold text-3xl text-gray-800">
          {props.pageData?.title}
        </h1>
        <p className="text-gray-700">
          Published {formatDate(new Date(props.pageData?.publishedDate))}
        </p>
        <section className="mt-10 content-body">
          <ContentfulContent content={props.pageData?.body.body} />
        </section>
      </section>
    </>
  );
};

const NewsItemLayout: React.FC = ({ pageContext }: any) => {
  return (
    <Layout>
      <SEO title={pageContext?.pageData?.title} />
      <ContentContainer>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/3 h-full mr-4">
            <NewsItemContent pageData={pageContext?.pageData} />
          </div>
          <div className="w-full lg:w-1/3 h-full ml-0 lg:ml-4 mt-8 lg:mt-0">
            <aside className="px-0 lg:px-8 pt-0">
              <h2 className="font-bold text-primary text-xl">Recent News</h2>
              <OtherNewsItems newsItems={pageContext?.latestNewsItems} />
            </aside>
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default NewsItemLayout;
